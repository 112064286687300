<template >
  <div class="overflow-y-hidden">
    <v-row>
      <v-dialog v-model="dialogDelete" max-width="380px"  transition="dialog-top-transition">
        <v-card class="pa-7">
          <v-card-title class="d-flex justify-center align-center">
            <v-row  dense>
              <v-col class="py-0 mb-1" cols="12">
                <span class="icon-background-alert">
                  <v-icon dense color="main_red" size="30px" class="my-0">mdi-alert-outline</v-icon>
                </span>
              </v-col>
              <v-col cols="12">
                <span>{{ $t('deleteQuestion') }}</span>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="text-center">{{ $t('questionTextForDelete') }}</v-card-text>
          <v-card-actions class="mb-2">
            <v-row dense>
              <v-col cols="12">
                <v-btn block depressed color="main_red" class="text-color-white" @click="deleteSurveyConfirmation">{{ $t('deleteField') }}</v-btn>
              </v-col>
              <v-col cols="12">
                <v-btn outlined depressed color="main_black" block @click="closeDeleteDialog">{{ $t("cancel") }}</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-data-table
        class="pa-1 mt-6 text-xs-center medical-staff-data-table"
        :headers="headers"
        :loading="loading"
        :no-data-text="$t('no-data-available')"
        :loading-text="$t('loading')"
        :headers-length="headers.length"
        :search="appBarSearch"
        :items="surveyTemplates"
        fixed-header
        dense
        :height="pageHeight(160)"
        :single-expand="true"
        ref="table"
        :footer-props="footerProps"
        :items-per-page="-1"
    >
      <template v-slot:item="{ item }">
        <tr style="height:7px;" @click="editDrawerDetails(item)" class="wellness-assessment-data-table">
          <td class="v-data-table-td text-left ml-n10" style="height:1px !important;">{{ item.createdByFullname }}</td>
          <td class="v-data-table-td" style="height:1px !important;">{{ item.templateName }}</td>
          <td class="v-data-table-td" style="height:7px !important;">{{ item.templateDescription  || $t('no-description-available') }}</td>
          <td class="text-left" style="height:7px !important;">
            <v-chip  pill x-small color="primary" class="custom-chip-size" label>{{ item.questionsListCount }} {{ item.questionsListCount > 1 ? $t('questions') : $t('question')}}</v-chip>
          </td>
          <td style="height:7px !important;">
            <div class="d-flex justify-end align-center">
              <v-icon class="mr-4" small :style="item.status === 1 ? 'color: #09CB09' : ''">mdi-checkbox-blank-circle</v-icon>
              <v-menu
                  left
                  bottom
                  class="py-0"
                  offset-y
                  transition="slide-x-transition"
              >
                <template v-slot:activator="{on, attrs}">
                    <v-icon   v-bind="attrs"
                  v-on="on">mdi-dots-horizontal</v-icon>
                </template>
                <v-list>
                  <v-list-item class="py-0 my-0" link dense @click="editDrawerDetails(item)">
                    <v-list-item-icon class="mx-1 ">
                      <v-icon class="icon-img mdi-18px" color="main_black">mdi-eye</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="font-bold font-size14">{{$t("view")}}</v-list-item-content>
                  </v-list-item>
                  <v-divider/>
                  <v-list-item class="py-0 my-0" link dense @click.stop="deleteSurveyById(item)">
                    <v-list-item-icon class="mx-1 ">
                      <v-icon class="icon-img mdi-18px" color="main_red">mdi-delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="font-bold font-size14">{{$t("delete")}}</v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import { showSuccessAlert, showErrorAlert } from '@/utils/utilities';
export default {
	data () {
		return {
      footerProps: { 'items-per-page-options': [-1, 5, 10, 50, 100] },
      surveyId: '',
			dialogDelete: false,
			loading: true,
		};
	},
	computed: {
		...mapState({
			userData: (state) => state.authentication.userData,
      surveyTemplates: (state) => state.mentalhealth.surveys,
		}),
		...mapGetters({
			hospitalId: 'authentication/getHospitalId',
			pageHeight: 'pageHeight',
			appBarSearch: 'filterbar/getSearch',
		}),
		editDetailsDrawer () {
			return this.$store.state.viewDetailsDrawer;
		},
		headers () {
			return [
				{ text: this.$t('created-by'), value: 'templateName', sortable: true, class: 'color-header', width: 50 },
        { text: this.$t('name'), value: 'templateName', sortable: true, class: 'color-header pl-4', width: 50 },
				{ text: this.$t('description'), value: 'templateDescription', sortable: true, class: 'color-header', width: 400 },
				{ text: this.$t('questions'), value: 'createdByFullname', sortable: true, class: 'color-header pl-7', width: 200 },
				{ text: this.$t('actions'), value: 'status', class: 'color-header', sortable: false, width: 50, align: 'end' },
			];
		},
	},
  async mounted () {
		await this.loadData();
	},
	methods: {
		async editDrawerDetails (item) {
			this.loading = true;
			if (!this.editDetailsDrawer) {
				await this.$store.dispatch('mentalhealth/getSurveysById', item.id);
				this.$store.commit('SET_EDIT_DETAILS_DRAWER', true);
			} else {
				this.$store.commit('SET_EDIT_DETAILS_DRAWER', false);
			}
			this.loading = false;
		},
		async deleteSurveyById (item) {
			this.dialogDelete = true;
			this.surveyId = item.id;
		},
    deleteSurveyConfirmation () {
      this.$store.dispatch('mentalhealth/deleteSurveysById', this.surveyId)
          .then((res) => {
            if (res.resFlag) {
              showSuccessAlert(this.$t('surveyDeleted'));
              this.loadData();
              this.closeDeleteDialog();
            } else {
              showErrorAlert(this.$t('delete-assesmet-error'));
              this.closeDeleteDialog();
            }
          });
    },
		closeDeleteDialog () {
			this.dialogDelete = false;
			this.surveyId = '';
		},
		loadData () {
			this.loading = true;
			// const hospitalId = this.userData.hospitalAssignedData.id;
      const hospital = localStorage.getItem('selectedHospitalAssignedId');
			this.$store
				.dispatch('mentalhealth/getMentalHealthSurveyTemplates', hospital).then(() => {
					this.loading = false;
				});
		},
	},
};
</script>
